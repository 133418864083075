@charset "utf-8";
@import "_variables";
@import "_extend";
@import "_mixin";

body:not(.front) {
	#pageHeader {
		.headerInner {
			height: auto;
		}
	}
}

/*下層ページスタイル調整用ファイル*/
#contents {
	padding: 150px 0;
	@include screen($breakpoint-tablet, $breakpoint-pc-small) {
		padding-top: 15.675vw;
	}
	@include screen($breakpoint-mobile, $breakpoint-tablet) {
		padding-top: 16vw;
	}
	@include max-screen($breakpoint-mobile-portrait) {
		padding-top: 80px;
		padding-bottom: 120px;
	}
	.contentsInner {
		:not(.front) & a {
			color: $color-orange;
			text-decoration: none;
			border-bottom: solid 1px $color-orange;
		}
		> article {
			> h1 {
				@extend %ttlMain;

				&.middle {
					font-size: 60px;
					margin-bottom: 45px;
					@include max-screen($breakpoint-mobile-portrait) {
						font-size: 34px;
					}
				}
			}
		}
		#searchArea {
			@extend %maxwidth;
			margin-bottom: 20px;
			@include max-screen($breakpoint-mobile-portrait) {
				margin-bottom: 20px;
			}
			h2 {
				background: #efefef;
				height: 16px;
				padding: 20px 0;
				font-size: 16px;
				text-align: center;
				text-transform: capitalize;
				line-height: 1;
				cursor: pointer;
				&:before {
					width: 0;
					height: 0;
					margin: -3px 6px 0 0;
					border-color: #000 transparent;
					border-style: solid;
					border-width: 6px 6px 0;
					display: inline-block;
					position: relative;
					top: -3px;
					content: "";
					vertical-align: baseline;
					transition: all 0.3s ease;
				}
				&.open:before {
					border-width: 0 6px 6px;
				}
			}
			.selectors {
				display: none;
				padding: 30px;
				border: solid 1px #efefef;
				@include max-screen($breakpoint-mobile-portrait) {
					padding: 0;
					border: none;
					display: block;
				}
				.condition {
					padding-bottom: 50px;
					@include min-screen($breakpoint-pc-small) {
						padding-bottom: 84px;
					}
					@include max-screen($breakpoint-mobile-portrait) {
						padding-bottom: 0;
					}
					li {
						list-style: none;
						display: block;
						@include min-screen($breakpoint-pc-small) {
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}
						+ li {
							margin-top: 30px;
							@include min-screen($breakpoint-pc-small) {
								margin-top: 50px;
							}
							@include max-screen($breakpoint-mobile-portrait) {
								margin-top: 0;
							}
						}
						h3 {
							background: $color-orange;
							height: 40px;
							margin-bottom: 10px;
							color: #fff;
							font-weight: normal;
							text-align: center;
							line-height: 40px;
							@include min-screen($breakpoint-pc-small) {
								align-self: flex-start;
								width: 208px;
								margin-bottom: 0;
								flex: none;
							}
							@include max-screen($breakpoint-mobile-portrait) {
								height: 50px;
								margin-bottom: 0;
								line-height: 50px;
							}
							&:before {
								@include max-screen($breakpoint-mobile-portrait) {
									width: 0;
									height: 0;
									margin-right: 6px;
									border-color: #fff transparent;
									border-style: solid;
									border-width: 6px 6px 0;
									display: inline-block;
									content: "";
									vertical-align: middle;
									transition: all 0.3s ease;
								}
							}
							&.open:before {
								@include max-screen($breakpoint-mobile-portrait) {
									border-width: 0 6px 6px;
								}
							}
						}
						.items {
							@include min-screen($breakpoint-pc-small) {
								padding-left: 38px;
							}
							@include max-screen($breakpoint-mobile-portrait) {
								display: none;
							}
							label {
								margin-right: 7px;
								display: inline-block;
								line-height: 2;
								&.checkbox {
									@extend %checkbox;
								}
								&.radio {
									@extend %radio;
								}
								input {
									margin-right: 7px;
								}
								&.select {
									@extend %select;
								}
							}
						}
					}
				}
				.btn {
					display: flex;
					flex-direction: column-reverse;
					font-size: 0;
					text-align: center;
					@include min-screen($breakpoint-pc-small) {
						display: block;
					}
					li {
						margin: 10px auto;
						font-size: 16px;
						list-style: none;
						@include min-screen($breakpoint-pc-small) {
							display: inline-block;
							margin: 10px 25px;
						}
						@include max-screen($breakpoint-mobile-portrait) {
							width: 100%;
							margin: 0 auto;
						}
						input {
							@extend %input;
							width: 220px;
							height: 56px;
							border: solid 1px;
							display: block;
							cursor: pointer;
							font-size: 16px;
							@include max-screen($breakpoint-mobile-portrait) {
								width: 100%;
								box-sizing: border-box;
							}
							&.reset {
								background: #fff;
								border-color: #efefef;
								color: #323232;
							}
							&.narrow {
								background: #323232;
								border-color: #323232;
								color: #fff;
							}
						}
					}
				}
			}
		}
		#searchNumber {
			@extend %maxwidth;
			margin-bottom: 65px;
			@include max-screen($breakpoint-mobile-portrait) {
				margin-bottom: 45px;
			}
			p {
				color: $color-orange;
				font-size: 14px;
			}
		}
	}
	.list & {
		.contentsInner {
			.listArea {
				@extend %maxwidth;
				ul {
					li {
						list-style: none;
					}
				}
			}
			.more.btnArea {
				margin-top: 100px;
				text-align: center;
				button {
					@extend %btn_form;
					&:after {
						width: 48px;
						height: 100%;
						display: block;
						position: absolute;
						right: 0;
						top: 0;
						text-align: left;
						line-height: 60px;
						@extend %iconfont;
						content: $icon_arrow_bottom;
					}
				}
			}
		}
	}
	.members.list & {
		.contentsInner {
			.listArea {
				@extend %listThumbnail;
				ul {
					li {
						width: calc((100% - 40px) / 3);
						margin-bottom: 40px;
						&:not(:nth-child(3n)) {
							margin-right: 20px;
							&:last-child {
								margin-right: auto;
							}
						}
						&:nth-child(3n) {
							margin-right: 0;
						}
						&:not(:nth-child(4n)) {
							@include min-screen($breakpoint-tablet) {
								margin-right: 20px;
							}
							&:last-child {
								@include min-screen($breakpoint-tablet) {
									margin-right: auto;
								}
							}
						}
						&:nth-child(4n) {
							@include min-screen($breakpoint-tablet) {
								margin-right: 0;
							}
						}
						&:nth-child(odd) {
							@include max-screen($breakpoint-mobile-portrait) {
								margin-right: 20px;
							}
							&:last-child {
								@include max-screen($breakpoint-mobile-portrait) {
									margin-right: auto;
								}
							}
						}
						&:nth-child(even) {
							@include max-screen($breakpoint-mobile-portrait) {
								margin-right: 0;
							}
						}
						@include min-screen($breakpoint-tablet) {
							width: calc((100% - 60px) / 4);
							margin-bottom: 75px;
						}
						@include max-screen($breakpoint-mobile-portrait) {
							width: calc((100% - 20px) / 2);
							margin-bottom: 30px;
						}
						a {
							figure {
								span {
									padding-top: 100%;
									img {
									}
								}
								figcaption {
									margin-top: 23px;
									color: #000;
									font-size: 14px;
									line-height: 1.75;
									@include min-screen($breakpoint-pc-small) {
										font-size: 16px;
									}
									.title {
									}
									.name {
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.works.list & {
		.contentsInner {
			.listArea {
				@extend %listWorks;
				> ul {
					> li {
						width: 50%;
						a {
							figure {
								span {
									img {
									}
								}
								figcaption {
									> div {
										width: 100%;
										height: 100%;
										flex: none;
										position: absolute;
										top: 0;
										left: 0;
										opacity: 0.9;
										z-index: -1;
									}
									.date {
										font-size: 14px;
										margin-bottom: 33px;
										@include screen($breakpoint-tablet, $breakpoint-pc-big) {
											margin-bottom: 2.35vw;
										}
										@include max-screen($breakpoint-tablet - 1px) {
											margin-bottom: 10px;
											font-size: 12px;
										}
									}
									.name {
										margin-bottom: 33px;
										font-size: 30px;
										line-height: 1.6;
										@include screen($breakpoint-tablet, $breakpoint-pc-big) {
											margin-bottom: 2.35vw;
											font-size: 2.35vw;
										}
										@include max-screen($breakpoint-tablet - 1px) {
											margin-bottom: 10px;
											font-size: 14px;
										}
									}
									.media {
										li {
											font-size: 14px;
											@include max-screen($breakpoint-tablet - 1px) {
												font-size: 12px;
											}
											+ li {
											}
										}
									}
								}
							}
							&:hover {
								figure {
									figcaption {
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.blog.list & {
		.listArea {
			ul {
				li {
					background: #fff;
					@extend %post;
					border: solid 1px #efefef;
					padding: 29px;
					margin-bottom: 50px;
				}
			}
		}
	}
	.recruit.list & {
		.listArea {
			ul {
				li {
					margin-bottom: 50px;
					border: solid 1px $color-orange;
					h2 {
						@extend %ttlBgfill;
						position: relative;
						&:before {
							@include max-screen($breakpoint-tablet - 1px) {
								width: 0;
								height: 0;
								margin-right: 6px;
								border-color: #fff transparent;
								border-style: solid;
								border-width: 6px 6px 0;
								display: inline-block;
								position: relative;
								top: -3px;
								content: "";
								vertical-align: baseline;
								transition: all 0.3s ease;
							}
						}
						&.open:before {
							@include max-screen($breakpoint-tablet - 1px) {
								border-width: 0 6px 6px;
							}
						}
					}
					.subject {
						padding: 30px;
						display: flex;
						align-items: flex-end;
						justify-content: space-between;
						@include max-screen($breakpoint-pc-small - 1px) {
							display: block;
						}
						@include max-screen($breakpoint-tablet - 1px) {
							padding: 15px;
							display: none;
						}
						.derection {
							width: calc(100% - 200px);
							flex: none;
							line-height: 1.75;
							@include max-screen($breakpoint-pc-small - 1px) {
								width: auto;
							}
							p {
								font-weight: bold;
							}
							.condition {
								margin-top: 1em;
								dt {
									width: 90px;
									font-weight: bold;
									float: left;
									clear: left;
									@include max-screen($breakpoint-mobile-portrait) {
										width: auto;
										float: none;
									}
								}
								dd {
									overflow: hidden;
								}
							}
						}
						.btnArea {
							width: 140px;
							flex: none;
							@include max-screen($breakpoint-pc-small - 1px) {
								width: auto;
							}
							.btn {
								@include max-screen($breakpoint-pc-small - 1px) {
									margin-top: 30px;
								}
								a {
									@extend %btn_a;
									padding: 10px 0;
									position: relative;
									height: auto;
									line-height: 1;
									&:after {
										width: 5px;
										height: 5px;
										margin-left: 5px;
										border: solid 1px;
										border-color: #fff #fff transparent transparent;
										display: inline-block;
										content: "";
										vertical-align: middle;
										transform: rotate(45deg);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.detail & {
		.ttlPage {
			@extend %ttlMain;
		}
		.btnBack {
			a {
				@extend %btn_allarea_back;
			}
		}
	}
	.members.detail & {
		article {
			li {
				list-style: none;
			}
			.profile {
				@extend %maxwidth;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				@include max-screen($breakpoint-pc-small - 1px) {
					flex-direction: column;
				}
				.information {
					order: 2;
					width: 100%;
					h1 {
						margin-bottom: 10px;
						font-size: 14px;
						font-weight: bold;
						span {
							font-weight: normal;
						}
					}
					.name {
						margin-bottom: 18px;
						font-size: 22px;
						font-weight: bold;
						@include max-screen($breakpoint-pc-small - 1px) {
							margin-bottom: 5px;
						}
					}
					.leadName {
						font-size: 18px;
					}
					.catch {
						margin-top: 50px;
						@include max-screen($breakpoint-pc-small - 1px) {
							margin-top: 35px;
						}
						h2 {
							margin-bottom: 15px;
							font-size: 18px;
						}
						.sentence {
							font-size: 16px;
							line-height: 1.75;
						}
					}
					.otherInfo {
						margin-top: 40px;
						font-size: 14px;
						line-height: 1.75;
						@include max-screen($breakpoint-pc-small - 1px) {
							margin-top: 30px;
						}
						dt {
							padding-right: 1em;
							float: left;
							clear: left;
							font-weight: bold;
						}
						dd {
							font-weight: normal;
						}
					}
				}
				.icon {
					width: 275px;
					margin-right: 50px;
					flex: none;
					order: 1;
					@include max-screen($breakpoint-pc-small - 1px) {
						width: 43%;
						max-width: 275px;
						margin: 0 auto 25px;
						text-align: center;
					}
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			.works {
				@extend %maxwidth;
				margin-top: 100px;
				text-align: center;
				@include max-screen($breakpoint-tablet - 1px) {
					margin-top: 75px;
				}
				h2 {
					margin-bottom: 50px;
					@extend %fontEnBoldI;
					font-size: 24px;
				}
				.listArea {
					@extend %listWorks;
					> ul {
						justify-content: space-between;
						> li {
							opacity: 1;
							@include min-screen($breakpoint-pc-small) {
								width: calc((100% - 70px) / 3);
								margin-bottom: 35px;
							}
							@include screen($breakpoint-tablet, $breakpoint-pc-small) {
								width: calc((100% - 35px) / 2);
								margin-bottom: 35px;
							}
							&:last-child:nth-child(3n + 2) {
								margin: {
									left: 35px;
									right: auto;
								}
								@include max-screen($breakpoint-pc-small) {
									margin: {
										left: 0;
									}
								}
							}
							a {
								figure {
									span {
										img {
										}
									}
									figcaption {
										background: rgba(100, 100, 100, 0.8);
										@include max-screen($breakpoint-tablet - 1px) {
											background: #f0f0f0;
											color: #000;
										}
										.name {
											font-size: 20px;
											line-height: 1.6;
											@include screen(
												$breakpoint-pc-small,
												$breakpoint-pc-big
											) {
												font-size: 1.5vw;
											}
											@include screen(
												$breakpoint-tablet,
												$breakpoint-pc-small
											) {
												font-size: 2vw;
											}
											@include max-screen($breakpoint-tablet - 1px) {
												margin-bottom: 10px;
												font-size: 14px;
											}
										}
										.media {
											margin-bottom: 10px;
											li {
												font-size: 14px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.blog {
				@extend %maxwidth;
				margin-top: 100px;
				@include max-screen($breakpoint-tablet - 1px) {
					margin-top: 75px;
				}
				h2 {
					margin-bottom: 50px;
					@extend %fontEnBoldI;
					font-size: 24px;
					text-align: center;
				}
				.post {
					@extend %post;
					border: solid 1px #efefef;
					padding: 29px;
				}
				.btn {
					margin: 100px auto;
					a {
						@extend %btn_a;
					}
				}
			}
		}
	}
	.works.detail & {
		article {
			@extend %maxwidth;
			.information {
				margin-bottom: 70px;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				@include max-screen($breakpoint-tablet - 1px) {
					flex-direction: column;
					justify-content: flex-start;
				}
				.notice {
					width: 63%;
					display: flex;
					flex: none;
					align-items: flex-start;
					justify-content: flex-start;
					flex-direction: column;
					@include max-screen($breakpoint-tablet - 1px) {
						width: auto;
						margin-bottom: 40px;
					}
					h1 {
						margin-bottom: 50px;
						font-size: 28px;
						line-height: 1.5;
						order: 1;
						@include max-screen($breakpoint-tablet - 1px) {
							margin-bottom: 25px;
							font-size: 14px;
						}
					}
					.sentence {
						margin-bottom: 1em;
						order: 1;
						font-size: 16px;
						line-height: 1.625;
						@include max-screen($breakpoint-tablet - 1px) {
							font-size: 13px;
						}
					}
					.links {
						order: 1;
						list-style: none;
					}
					.date {
						order: 0;
						font-size: 18px;
						font-weight: bold;
						@include max-screen($breakpoint-tablet - 1px) {
							font-size: 13px;
						}
					}
				}
				.dear {
					width: calc(37% - 60px);
					flex: none;
					font-size: 14px;
					@include max-screen($breakpoint-tablet - 1px) {
						width: auto;
					}
					.outside {
						margin-bottom: 1em;
						p {
						}
					}
					.inside {
						ul {
							list-style: none;
							li {
								margin-bottom: 10px;
								a {
									font-weight: 400;
								}
							}
						}
					}
				}
			}
			.block {
				text-align: center;
				figure {
					img {
						width: auto;
						max-width: 100%;
						height: auto;
					}
					figcaption {
					}
				}
				+ .block {
					margin-top: 70px;
				}
			}
		}
		.btnBack {
			margin: 150px auto 0;
			@include max-screen($breakpoint-tablet - 1px) {
				margin: 75px auto 0;
			}
		}
	}
	.blog.post & {
		.ttlPage {
			@extend %ttlMain;
		}
		article {
			padding: 7.8vw 8.1% 10vw;
			border: solid 1px #efefef;
			@extend %maxwidth;
			box-sizing: border-box;
			@include min-screen($breakpoint-pc-big) {
				padding: 100px 150px 128px;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				padding: 15px;
			}
			.headArea {
				@extend %posthead;
			}
			.postArea {
				line-height: 1.625;
				img {
					max-width: 100%;
					width: auto;
					height: auto;
				}
				%blockgap {
					margin-top: 2em;
				}
				.block {
					& + .block {
						@extend %blockgap;
					}
					p + p {
						@extend %blockgap;
					}
				}
			}
			.navArea {
				margin-top: 100px;
				@include max-screen($breakpoint-mobile-portrait) {
					margin-top: 30px;
				}
				ul {
					display: flex;
					align-items: center;
					justify-content: space-between;
					list-style: none;
					@include max-screen($breakpoint-pc-small - 1px) {
						flex-wrap: wrap;
					}
					li {
						width: calc((100% - 6.265%) / 3);
						flex: none;
						@include max-screen($breakpoint-pc-small - 1px) {
							width: calc((100% - 3.132%) / 2);
						}
						a {
							@extend %btn_a;
							margin: 0;
							padding: 0;
							max-width: none;
							position: relative;
							&:after {
								width: 19.2%;
								margin-top: -8px;
								@extend %iconfont;
								display: block;
								position: absolute;
								top: 50%;
								font-size: 16px;
								line-height: 1;
								@include max-screen($breakpoint-tablet - 1px) {
									width: auto;
									margin-top: 10px;
									text-align: center !important;
									position: static;
								}
							}
						}
						&.all {
							@include max-screen($breakpoint-pc-small - 1px) {
								width: 100%;
								margin-top: 10px;
								order: 3;
							}
						}
						&.prev {
							@include max-screen($breakpoint-pc-small - 1px) {
								order: 1;
							}
							a {
								@include max-screen($breakpoint-tablet - 1px) {
									height: auto;
									padding: 15px 0;
									line-height: 1;
								}
								&:after {
									left: 0;
									text-align: right;
									content: $icon_arrow_left;
								}
							}
						}
						&.next {
							@include max-screen($breakpoint-pc-small - 1px) {
								order: 2;
							}
							a {
								@include max-screen($breakpoint-tablet - 1px) {
									height: auto;
									padding: 15px 0;
									line-height: 1;
								}
								&:after {
									right: 0;
									text-align: left;
									content: $icon_arrow_right;
								}
							}
						}
					}
				}
			}
		}
	}
	.recruit.detail & {
		.condition {
			@extend %maxwidth;
			border: solid 1px $color-orange;
			box-sizing: border-box;
			h1 {
				@extend %ttlBgfill;
			}
			table {
				width: calc(100% - 60px);
				margin: 0 30px;
				line-height: 1.75;
				@include max-screen($breakpoint-tablet - 1px) {
					width: auto;
					display: block;
					margin: 0 15px;
				}
				tbody,
				tr,
				th,
				td {
					@include max-screen($breakpoint-tablet - 1px) {
						width: auto;
						display: block;
					}
				}
				tr {
					@include max-screen($breakpoint-tablet - 1px) {
						padding: 14px 0 7px;
					}
					> * {
						padding: {
							top: 30px;
							bottom: 30px;
						}
						@include max-screen($breakpoint-tablet - 1px) {
							padding: {
								top: 0;
								bottom: 7px;
							}
						}
					}
					th {
						padding-right: 10px;
						vertical-align: top;
						font-weight: bold;
						white-space: nowrap;
						br {
							@include max-screen($breakpoint-tablet - 1px) {
								display: none;
							}
						}
					}
					td {
						@include max-screen($breakpoint-tablet - 1px) {
							font-size: 13px;
						}
					}
					+ tr {
						@include max-screen($breakpoint-tablet - 1px) {
							border-top: solid 1px #efefef;
						}
						> * {
							border-top: solid 1px #efefef;
							@include max-screen($breakpoint-tablet - 1px) {
								border-top: none;
							}
						}
					}
				}
			}
		}
		.btnArea {
			margin-top: 60px;
			.btn {
				a {
					@extend %btn_a;
				}
			}
		}
	}
	.service & {
		.starea {
			&Head {
				margin-bottom: 50px;
				h2 {
					font-size: 50px;
					line-height: 140%;
					text-align: center;
					font-family: $font-EN;
					font-style: italic;

					&.font-jp {
						font-family: $font_yugo;
						font-style: normal;
						font-size: 30px;
						@include max-screen($breakpoint-mobile-portrait) {
							font-size: 18px;
							line-height: 1.6;
						}
					}
				}
				@include max-screen($breakpoint-mobile-portrait) {
					margin-bottom: 30px;
					h2 {
						font-size: 26px;
					}
				}
			}
			&Circle {
				position: relative;
				z-index: 1;
				font-size: 0;
				text-align: center;
				&:before {
					content: "";
					width: 100%;
					height: 1px;
					background: #f25d32;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					z-index: -1;
				}
				.box {
					max-width: 240px;
					max-height: 240px;
					width: calc(84% / 3);
					height: calc(84vw / 3);
					background: #ffffff;
					border: 1px solid #f25d32;
					border-radius: 50%;
					display: inline-block;
					vertical-align: top;
					+ .box {
						margin-left: 3%;
					}
					p {
						font-size: 20px;
						color: #f25d32;
						font-weight: 700;
						line-height: 190%;
						position: relative;
						top: 50%;
						transform: translateY(-50%);
						span {
							font-weight: 900;
							font-size: 150%;
							letter-spacing: -0.5px;
							padding: 0 2px;
						}
					}
				}
				@include max-screen($breakpoint-mobile-portrait) {
					&:before {
						width: 1px;
						height: 400px;
					}
					.box {
						width: 180px;
						height: 180px;
						display: block;
						margin-left: auto;
						margin-right: auto;
						+ .box {
							margin-left: auto;
							margin-top: 20px;
						}
						p {
							line-height: 160%;
							font-size: 18px;
						}
					}
				}
			}
			.svgreat {
				text-align: center;
				margin: 60px auto;
				width: 90%;
				p {
					line-height: 180%;
					letter-spacing: 0.5px;
				}
				@include max-screen($breakpoint-mobile-portrait) {
					margin: 40px auto;
					p {
						font-size: 14px;
					}
				}
			}
		}
		.creation {
			background: #f25d32;
			padding: 80px 0;
			border-bottom: 1px solid #ffffff;
			h2 {
				font-size: 46px;
				color: #ffffff;
				font-style: italic;
				font-family: Montserrat, sans-serif;
				text-align: center;
				line-height: 140%;
				margin-bottom: 50px;
				@include max-screen($breakpoint-mobile-portrait) {
					font-size: 32px;
				}

				&.font-jp {
					font-family: $font_yugo;
					font-style: normal;
					font-size: 30px;
					@include max-screen($breakpoint-mobile-portrait) {
						font-size: 18px;
					}
				}
			}
			.team {
				margin: 0 auto;
				border-radius: 50px;
				text-align: center;
				padding: 40px 0;
				max-width: 720px;
				width: 90%;

				h3 {
					font-family: Montserrat, sans-serif;
					letter-spacing: 6px;
					font-weight: 500;
					font-size: 28px;
					color: #ffffff;
					line-height: 140%;
				}
				&box {
					margin: 40px -10px 0;
					font-size: 0;
					display: flex;
					flex-wrap: wrap;
					.circ {
						vertical-align: top;
						width: calc(25% - 20px);
						height: calc((90vw * 0.25) - 20px);
						max-height: 165px;
						margin: 10px;
						background: #ffffff;
						border-radius: 50%;
						box-shadow: 3px 3px 12px -2px rgba(0, 0, 0, 0.2);
						transition: ease-out 0.16s opacity;
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
						p {
							font-size: 16px;
							font-weight: bold;
							line-height: 130%;
							margin-top: 3px;
							min-height: 40px;
							display: flex;
							display: -ms-flexbox;
							justify-content: center;
							-ms-flex-pack: center;
							align-items: center;
							-ms-align-items: center;
							-webkit-backface-visibility: hidden;
							backface-visibility: hidden;
						}
						span {
							width: 14px;
							height: 14px;
							position: relative;
							display: block;
							margin: 8px auto 0;
							-webkit-backface-visibility: hidden;
							backface-visibility: hidden;
							svg {
								-webkit-backface-visibility: hidden;
								backface-visibility: hidden;
							}
							.arwst {
								fill: none;
								stroke-width: 1.0667;
								stroke-miterlimit: 10;
							}
						}
						.inner {
							position: relative;
							top: 50%;
							transform: translateY(-50%);
						}
						&.gra {
							p {
								color: #f15c31;
							}
							span .arwst {
								stroke: #f15c31;
							}
						}
						&.web {
							p {
								color: #716ab0;
							}
							span .arwst {
								stroke: #716ab0;
							}
						}
						&.vid {
							p {
								color: #64b3ad;
							}
							span .arwst {
								stroke: #64b3ad;
							}
						}
						&.edi {
							p {
								color: #fcd108;
							}
							span .arwst {
								stroke: #fcd108;
							}
						}
						&.pr {
							p {
								color: #8dc21f;
							}
							span .arwst {
								stroke: #8dc21f;
							}
						}
						&.eve {
							p {
								color: #66a9dd;
							}
							span .arwst {
								stroke: #66a9dd;
							}
						}
						&.tv {
							p {
								color: #39b54a;
							}
							span .arwst {
								stroke: #39b54a;
							}
						}
						&.system {
							p {
								color: #dc8cc8;
							}
							span .arwst {
								stroke: #dc8cc8;
							}
						}

						@include max-screen($breakpoint-mobile-portrait) {
							width: calc(50% - 20px);
							height: calc((90vw * 0.5) - 10px);
							max-height: none;
							p {
								font-size: 15px;
							}
						}
						&:hover {
							opacity: 0.6;
						}
					}
				}
				@include max-screen($breakpoint-mobile-portrait) {
					border-radius: 0;
					padding: 0;
					h3 {
						font-size: 22px;
						width: 260px;
						margin: 0 auto;
					}
				}
			}
			@include max-screen($breakpoint-mobile-portrait) {
				padding: 40px 0 60px;
				h2 {
					margin-bottom: -20px;
				}
			}
		}
		.svlead {
			text-align: center;
			width: 90%;
			margin: 40px auto;
			p {
				line-height: 200%;
				color: #ffffff;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				margin-bottom: 60px;
				p {
					font-size: 14px;
				}
			}
		}
		.lead {
			background: $color-orange;
			padding: 7.1428vw 0;
			@include min-screen($breakpoint-pc-big) {
				padding: 100px 0;
			}
			.inner {
				@extend %maxwidth;
				color: #fff;
				text-align: center;
				h2 {
					margin-bottom: 3.571vw;
					@extend %fontEnBoldI;
					font-size: 40px;
					line-height: 1;
					@include min-screen($breakpoint-pc-big) {
						margin-bottom: 50px;
					}
				}
				> div {
					margin-bottom: 7.1428vw;
					line-height: 2;
					@include min-screen($breakpoint-pc-big) {
						margin-bottom: 100px;
					}
					@include max-screen($breakpoint-tablet - 1px) {
						text-align: left;
					}
					p {
						font-size: 16px;
						@include max-screen($breakpoint-tablet - 1px) {
							font-size: 14px;
						}
					}
				}
				> figure {
					width: 100%;
					max-width: 772px;
					margin: 0 auto;
					img {
						width: 100%;
						height: auto;
						&:first-child {
							@include max-screen($breakpoint-mobile-portrait) {
								display: none;
							}
						}
						&:nth-child(2) {
							@include min-screen($breakpoint-mobile) {
								display: none;
							}
						}
					}
				}
			}
			.pointArea {
				display: flex;
				display: -ms-flexbox;
				justify-content: space-between;
				-ms-flex-pack: justify;
				margin-bottom: 0 !important;
				@include max-screen($breakpoint-pc-small) {
					display: block;
				}
				.pointAreaBox {
					border: 1px solid #ffffff;
					width: 32%;
					max-width: 330px;
					padding: 20px 30px;
					box-sizing: border-box;
					h3 {
						font-size: 29px;
						font-family: $font-EN;
						margin-bottom: 5px;
						@include max-screen($breakpoint-mobile) {
							text-align: center;
						}
					}
					p {
						font-size: 19px;
						letter-spacing: 2px;
						text-align: center;
						font-weight: bold;
					}
					@include max-screen($breakpoint-pc-small) {
						width: 100%;
						max-width: 420px;
						margin: 0 auto;
						padding-bottom: 25px;
						+ .pointAreaBox {
							margin-top: 20px;
						}
						h3 {
							font-size: 27px;
							margin-bottom: 0;
						}
						p {
							font-size: 18px;
							letter-spacing: 1.5px;
						}
					}
					@include max-screen($breakpoint-mobile) {
						padding: 10px 7% 20px;
						+ .pointAreaBox {
							margin-top: 10px;
						}
						h3 {
							font-size: 23px;
						}
						p {
							font-size: 16px;
							line-height: 180%;
						}
					}
				}
			}
		}
		.listArea {
			@extend %maxwidth;
			margin-top: 13.214vw;
			@include min-screen($breakpoint-pc-big) {
				margin-top: 120px;
			}
			ul {
				list-style: none;
			}
			> ul {
				> li {
					margin-bottom: 7.142vw;
					@include min-screen($breakpoint-pc-big) {
						margin-bottom: 100px;
					}
					h2 {
						padding-bottom: 4.285vw;
						font-size: 15px;
						font-weight: bold;
						text-align: center;
						&.spaconone {
							&:before,
							&:after {
								content: none;
							}
							@include max-screen($breakpoint-mobile-portrait) {
								padding-bottom: 40px;
							}
						}
						@include min-screen($breakpoint-pc-big) {
							padding-bottom: 60px;
						}
						@include max-screen($breakpoint-mobile-portrait) {
							padding-bottom: 60px;
							position: relative;
						}
						&:before {
							width: 100%;
							display: block;
							position: absolute;
							bottom: 40px;
							left: 0;
							font-size: 12px;
							line-height: 1;
							@extend %fontEnBoldI;
							text-align: center;
							@include max-screen($breakpoint-mobile-portrait) {
								content: "open";
							}
						}
						&.open:before {
							@include max-screen($breakpoint-mobile-portrait) {
								content: "close";
							}
						}
						&:after {
							@include max-screen($breakpoint-mobile-portrait) {
								width: 0;
								height: 0;
								margin-left: -20px;
								border-color: #000 transparent;
								border-style: solid;
								border-width: 10px 20px 0;
								display: block;
								position: absolute;
								bottom: 25px;
								left: 50%;
								content: "";
								transition: all 0.3s ease;
							}
						}
						&.open:after {
							@include max-screen($breakpoint-mobile-portrait) {
								border-width: 0 20px 10px;
							}
						}
						span {
							margin-bottom: 10px;
							font-size: 24px;
							display: block;
							@extend %fontEnBoldI;
						}
						+ p {
							font-size: 15px;
							text-align: center;
							letter-spacing: 1px;
							line-height: 180%;
							max-width: 740px;
							margin: -20px auto 50px;
							font-weight: 600;
							@include max-screen($breakpoint-mobile) {
								margin-bottom: 30px;
							}
						}
					}
					> ul {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						@include max-screen($breakpoint-mobile-portrait) {
							display: none;
						}
						> li {
							width: 24vw;
							height: 24vw;
							flex: none;
							box-sizing: border-box;
							margin-bottom: 4vw;
							padding: 2.181vw 2.181vw 0;
							border: solid 1px #efefef;
							display: flex;
							flex-wrap: wrap;
							align-content: flex-start;
							justify-content: space-between;
							@include min-screen($breakpoint-pc-big) {
								width: 330px;
								height: 330px;
								margin-bottom: 55px;
								padding: 29px 29px 0;
							}
							@include screen($breakpoint-mobile, $breakpoint-pc-small) {
								width: 38vw;
								height: 38vw;
								padding: 3.4545vw 3.4545vw 0;
							}
							@include max-screen($breakpoint-mobile-portrait) {
								width: 100%;
								height: calc(100vw - 32px);
								padding: {
									top: calc((100vw - 32px) * (29 / 330));
									right: calc((100vw - 32px) * (29 / 330));
									bottom: 0;
									left: calc((100vw - 32px) * (29 / 330));
								}
								margin-bottom: 10px;
							}
							h3 {
								font-size: 1.42vw;
								height: 2.8em;
								line-height: 1.4;
								white-space: nowrap;
								@include min-screen($breakpoint-pc-big) {
									font-size: 20px;
								}
								@include screen($breakpoint-mobile, $breakpoint-pc-small) {
									font-size: 2.1vw;
								}
								@include max-screen($breakpoint-mobile-portrait) {
									font-size: calc((100vw - 32px) * (20 / 330));
								}
							}
							figure {
								width: 18.5%;
								max-width: 50px;
								display: block;
								flex: none;
								img {
									width: 100%;
									height: auto;
								}
							}
							> div {
								flex: none;
								width: 100%;
								p {
									padding-top: 2.857vw;
									font-size: 1.142vw;
									font-weight: 400;
									line-height: 1.75;
									text-align: justify;
									text-justify: inter-ideograph;
									@include min-screen($breakpoint-pc-big) {
										padding-top: 35px;
										font-size: 16px;
									}
									@include screen($breakpoint-mobile, $breakpoint-pc-small) {
										padding-top: 4.225vw;
										font-size: 1.808vw;
									}
									@include max-screen($breakpoint-mobile-portrait) {
										padding-top: calc((100vw - 32px) * (35 / 330));
										font-size: calc((100vw - 32px) * (16 / 330));
									}
								}
							}
						}
						&.worksList {
							justify-content: center;
							> li {
								border: none;
								padding: 0;
								height: auto;
								+ li {
									margin-left: calc((100% - (330px * 3)) / 2);
								}
								figure {
									width: 100%;
									max-width: none;
									border: solid 1px #efefef;
									box-sizing: border-box;
									overflow: hidden;
									position: relative;
									&:before {
										content: "";
										display: block;
										position: absolute;
										top: 0;
										bottom: 0;
										left: 0;
										right: 0;
										margin: auto;
										background: rgba(80, 50, 30, 0.13);
										opacity: 0;
										transition: ease-out 0.12s opacity;
									}
								}
								p {
									font-size: 14px;
									font-weight: 500;
									line-height: 140%;
									margin: 10px 0;
								}
								a {
									border: none;
									color: inherit;
									display: block;
									width: 100%;
									&:not(.none):hover {
										figure:before {
											opacity: 1;
										}
									}
								}
							}
							@include max-screen($breakpoint-mobile-portrait) {
								display: block;
								li + li {
									margin-left: auto;
								}
							}
						}
						&.memberList {
							display: block;
							> li {
								display: flex;
								display: -ms-flexbox;
								width: 100%;
								height: auto;
								padding: 0;
								border: none;
								figure {
									width: 330px;
									max-width: none;
									height: auto;
									a {
										transition: ease-out 0.14s opacity;
										&:hover {
											opacity: 0.8;
										}
									}
								}
								.txt {
									width: calc(97% - 330px);
									border-top: 2px solid #f7f7f7;
									padding-top: 20px;
									p {
										letter-spacing: 0.5px;
										padding-top: 0;
										font-weight: bold;
									}
									.posi {
										font-family: $font-EN;
										letter-spacing: 0;
									}
									.name {
										font-size: 20px;
										a {
											transition: ease-out 0.14s opacity;
											&:hover {
												opacity: 0.7;
											}
										}
									}
									p:not(.name):not(.posi) {
										line-height: 180%;
										padding-top: 22px;
									}
								}
								&:nth-of-type(even) {
									//偶数
									flex-direction: row-reverse;
								}
								@include max-screen($breakpoint-pc-small) {
									figure {
										width: 42%;
									}
									.txt {
										width: 55%;
										p:not(.name):not(.posi) {
											padding-top: 10px;
										}
									}
								}
								@include max-screen($breakpoint-mobile) {
									display: block;
									margin-bottom: 30px;
									figure,
									.txt {
										width: 100%;
									}
									figure {
										height: calc((100vw - 32px) * 0.63);
										overflow: hidden;
										img {
											display: block;
											transform: translateY(-7%);
										}
									}
									.txt {
										padding-top: 8px;
										border-top: none;
										.posi {
											font-size: 14px;
										}
										.name {
											font-size: 17px;
										}
										p:not(.name):not(.posi) {
											font-size: 14px;
											letter-spacing: 0.2px;
											padding-top: 5px;
										}
									}
								}
							}
						}
					}
					.btnMoreArea {
						text-align: center;
						a {
							width: 250px;
							background: #f15d30;
							display: inline-block;
							padding: 18px 0;
							border: none;
							transition: ease-out 0.16s background;
							p {
								font-size: 14px;
								color: #ffffff;
								line-height: 140%;
								font-weight: 500;
							}
							&:hover {
								background: #d64c20;
							}
						}
					}
				}
			}
		}
		.btnArea {
			margin-top: 60px;
			a {
				font-size: 22px;
				color: #ffffff;
				text-align: center;
				display: block;
				width: 90%;
				max-width: 400px;
				margin: 0 auto;
				padding: 26px 0;
				letter-spacing: 1px;
				background: #f25d32;
				border: 0;
				border-radius: 50px;
				transition: ease-out 0.3s background;
				&:hover {
					background: #000;
				}
				@include max-screen($breakpoint-mobile-portrait) {
					font-size: 16px;
					padding: 19px 0;
				}
			}
		}
		.cvBtn {
			position: fixed;
			bottom: 2vw;
			left: 2vw;
			max-width: 152px;
			width: 30%;
			z-index: 10;
			a {
				border: none;
				display: block;
				transition: cubic-bezier(0.29, 0.37, 0.3, 2) 0.2s transform;
				&:before {
					display: none;
				}
				svg#srv_contact {
					.st0a,
					.st1 {
						fill: #f25d32;
						transition: ease-out 0.2s fill;
					}
				}
				&.top svg#srv_contact {
					.st1 {
						fill: #ffffff;
					}
					.st0a,
					.st0 {
						fill: #f25d32;
					}
				}
				&.wh svg#srv_contact .st0a {
					fill: #ffffff !important;
				}
				&.graphic svg#srv_contact {
					.st0a,
					.st1 {
						fill: #f25d32;
					}
				}
				&.web svg#srv_contact {
					.st0a,
					.st1 {
						fill: #716ab0;
					}
				}
				&.movie svg#srv_contact {
					.st0a,
					.st1 {
						fill: #64b3ad;
					}
				}
				&.edit svg#srv_contact {
					.st0a,
					.st1 {
						fill: #fcd108;
					}
				}
				&.event svg#srv_contact {
					.st0a,
					.st1 {
						fill: #66a9dd;
					}
				}
				&.promotion svg#srv_contact {
					.st0a,
					.st1 {
						fill: #8dc21f;
					}
				}
				&.tv svg#srv_contact {
					.st0a,
					.st1 {
						fill: #39b54a;
					}
				}
				&.system svg#srv_contact {
					.st0a,
					.st1 {
						fill: #dc8cc8;
					}
				}

				&:hover {
					transform: scale(1.1) translateY(-5%);
				}
				@include max-screen($breakpoint-mobile-portrait) {
				}
			}
		}
	}
	.contact & {
		table {
			width: 100%;
			table-layout: fixed;
			@include max-screen($breakpoint-tablet - 1px) {
				display: block;
			}
			tbody {
				@include max-screen($breakpoint-tablet - 1px) {
					display: block;
				}
				tr {
					font-size: 16px;
					vertical-align: top;
					line-height: 1.5;
					@include max-screen($breakpoint-tablet - 1px) {
						display: block;
					}
					> * {
					}
					th {
						width: 150px;
						padding: 32px 0;
						border-bottom: solid 1px #efefef;
						font-weight: bold;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							padding: 20px 0 0;
							border-bottom: none;
							display: inline-block;
						}
						br {
							@include max-screen($breakpoint-tablet - 1px) {
								display: none;
							}
						}
					}
					td {
						width: calc(100% - (150px + 88px));
						border-bottom: solid 1px #efefef;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							padding: 0;
							display: block;
						}
						&:not(.must) {
							padding: 0;
						}
						&.must {
							width: 88px;
							padding: 32px 0;
							@include max-screen($breakpoint-tablet - 1px) {
								width: auto;
								padding: 18px 0 0;
								border-bottom: none;
								display: inline-block;
							}
							span {
								background: $color-orange;
								padding: 2px 4px;
								color: #fff;
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		.btnArea {
			text-align: center;
			.btn {
				@extend %btn_form;
				&.back {
					background: #b3b3b3;
				}
			}
		}
		#tabArea {
			position: relative;
			margin-bottom: 70px;
			@include max-screen($breakpoint-mobile-portrait) {
				margin-bottom: 10px;
			}
			&:after {
				background: #b4b4b4;
				width: 100%;
				height: 1px;
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
				content: "";
			}
			ul {
				@extend %maxwidth;
				display: flex;
				align-items: center;
				flex: none;
				position: relative;
				z-index: 2;
				li {
					background: #f7f8f8;
					width: calc(100% / 3);
					border: solid 1px #b4b4b4;
					padding: 20px 0;
					box-sizing: border-box;
					flex: none;
					color: #b4b4b4;
					font-size: 16px;
					text-align: center;
					list-style: none;
					cursor: pointer;
					@include max-screen($breakpoint-mobile-portrait) {
						padding: 10px 0;
						font-size: 12px;
					}
					+ li {
						border-left: none;
					}
					&.current {
						background: #fff;
						color: #000;
						border-bottom-color: #fff;
					}
				}
			}
		}
		.input_area_div {
			@extend %maxwidth;
			@include max-screen($breakpoint-mobile-portrait) {
				padding-top: 20px;
			}
			&:not(.current) {
				display: none;
			}
			.formText {
				width: 100%;
				border: none;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.5;
				@include placeholderColor(#a0a0a0);
				@include max-screen($breakpoint-tablet - 1px) {
					margin-top: auto;
				}
			}
			select {
				font-size: 16px;
			}
			input.formText {
				padding: 32px 10px;
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 10px;
				}
			}
			textarea.formText {
				height: 100px;
				margin-top: 22px;
				padding: 10px;
				@include max-screen($breakpoint-tablet - 1px) {
					margin-top: 0;
				}
			}
			label {
				display: inline-block;
				&:not(.select) {
					margin: 32px 2em 32px auto;
					@include max-screen($breakpoint-mobile-portrait) {
						margin: 0 auto;
					}
				}
				&:first-child {
					@include max-screen($breakpoint-mobile-portrait) {
						margin-top: 10px;
					}
				}
				&.select {
					@extend %select;
					@include min-screen($breakpoint-mobile) {
						margin: 21px 0 0;
						width: 300px;
					}
					@include max-screen($breakpoint-mobile-portrait) {
						margin: 10px auto 0;
					}
				}
				&.checkbox {
					@extend %checkbox;
				}
				&.radio {
					@extend %radio;
				}
				a {
				}
			}
		}
		#send_form_area {
			@extend %maxwidth;
			th {
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 5px 0;
				}
			}
			td {
				padding: 32px 0;
				line-height: 1.5;
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 5px 0;
				}
				&.must {
					line-height: 1;
				}
			}
		}
		#send_msg {
			@extend %maxwidth;
			text-align: center;
			line-height: 1.5;
			padding: 50px 0 0;
			@include max-screen($breakpoint-mobile-portrait) {
				text-align: left;
			}
			.btnArea {
				padding: 100px 0 0;
				text-align: center;
				a {
					@extend %btn_a;
				}
			}
		}
		.agree {
			padding: 100px 0 50px;
			text-align: center;
			@include max-screen($breakpoint-pc-small - 1px) {
				padding: 50px 0;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 14px;
			}
			label {
				@include max-screen($breakpoint-mobile-portrait) {
					text-align: left !important;
					padding: 10px 10px 10px 40px;
					line-height: 1.5 !important;
				}
				&:before {
					@include max-screen($breakpoint-mobile-portrait) {
						left: 10px !important;
					}
				}
				&:after {
					@include max-screen($breakpoint-mobile-portrait) {
						left: 10px !important;
					}
				}
			}
		}
	}
	.about & {
		article {
			> h1 {
				@extend %ttlMain;
			}
		}
		section.lead {
			background: $color-orange;
			padding: 100px 0;
			color: #fff;
			@include max-screen($breakpoint-pc-small - 1px) {
				padding: 60px 0;
			}
			.wide {
				@include max-screen($breakpoint-tablet - 1px) {
					display: none;
				}
			}
			h2 {
				margin-bottom: 40px;
				@extend %fontEnBoldI;
				font-size: 60px;
				text-align: center;
				line-height: 1;
				@include max-screen($breakpoint-pc-small - 1px) {
					margin-bottom: 20px;
					font-size: 30px;
				}
				span {
					margin-bottom: 40px;
					display: block;
					font-size: 24px;
					@include max-screen($breakpoint-pc-small - 1px) {
						font-size: 18px;
						margin-bottom: 20px;
					}
				}
			}
			p {
				font-size: 16px;
				line-height: 2;
				@extend %maxwidth;
				text-align: center;
				@include max-screen($breakpoint-pc-small - 1px) {
					font-size: 14px;
				}
				@include max-screen($breakpoint-tablet - 1px) {
					text-align: left;
				}
			}
		}
		.ceo {
			background: #efefef;
			height: 600px;
			display: flex;
			flex-direction: row-reverse;
			@include max-screen($breakpoint-pc-small - 1px) {
				height: auto;
				position: relative;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				flex-direction: column;
			}
			> div {
				flex: none;
				width: 50%;
				padding: 7.142vw;
				box-sizing: border-box;
				@include min-screen($breakpoint-pc-big) {
					padding: 100px;
				}
				@include max-screen($breakpoint-pc-small - 1px) {
					width: 70%;
					box-sizing: border-box;
					padding: 2vw;
					/*position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2*/
				}
				@include max-screen($breakpoint-mobile-portrait) {
					background: none;
					width: auto;
					padding: 15px;
				}
				h2 {
					margin-bottom: 2.857vw;
					font-size: 3.1428vw;
					font-weight: bold;
					line-height: 1;
					@include min-screen($breakpoint-pc-big) {
						margin-bottom: 43px;
						font-size: 30px;
					}
					@include max-screen($breakpoint-pc-small - 1px) {
						margin-bottom: 2.83vw;
					}
					@include max-screen($breakpoint-mobile-portrait) {
						font-size: 6.25vw;
					}
					span {
						margin-bottom: 22px;
						display: block;
						@extend %fontEnMediumI;
						font-size: 1.7142vw;
						@include min-screen($breakpoint-pc-big) {
							font-size: 24px;
						}
						@include max-screen($breakpoint-pc-small - 1px) {
							margin-bottom: 10px;
						}
						@include max-screen($breakpoint-mobile-portrait) {
							display: inline-block;
							margin-right: 1em;
							font-size: 5vw;
						}
					}
				}
				p {
					max-width: 500px;
					font-size: 1.25vw;
					line-height: 2;
					text-align: justify;
					text-justify: inter-ideograph;
					@include min-screen($breakpoint-pc-big) {
						font-size: 16px;
					}
					@include max-screen($breakpoint-pc-small - 1px) {
						max-width: none;
						font-size: 2vw;
						line-height: 1.3;
					}
					@include max-screen($breakpoint-mobile-portrait) {
						font-size: 4.375vw;
						line-height: 1.5;
					}
				}
			}
			> figure {
				flex: none;
				width: 50%;
				text-align: right;
				display: block;
				position: relative;
				overflow: hidden;
				@include max-screen($breakpoint-pc-small - 1px) {
					width: 30%;
				}
				@include max-screen($breakpoint-mobile-portrait) {
					width: auto;
					padding: 10px;
					text-align: center;
				}
				img {
					width: auto;
					height: auto;
					min-width: 100%;
					min-height: 100%;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					@include min-screen($breakpoint-pc-big) {
						min-width: 0;
						min-height: 0;
						height: 100%;
						left: auto;
						right: 0;
						transform: translate(0, -50%);
					}
					@include max-screen($breakpoint-pc-small - 1px) {
						width: auto;
						height: 100%;
						/*position: static;
						width: 80%;
						height: auto;
						transform: translate(0);*/
					}
					@include max-screen($breakpoint-mobile-portrait) {
						width: 100%;
						height: auto;
						min-height: 0;
						min-width: 0;
						position: static;
						transform: translate(0);
					}
				}
			}
		}
		.corporateProf {
			@extend %maxwidth;
			padding: 100px 0;
			@include max-screen($breakpoint-pc-big - 1px) {
				padding: 8.14vw 0;
			}
			h2 {
				margin-bottom: 70px;
				font-size: 24px;
				@extend %fontEnBoldI;
				text-align: center;
				@include max-screen($breakpoint-pc-big - 1px) {
					margin-bottom: 6vw;
				}
			}
			table {
				width: 80%;
				margin: 0 auto;
				line-height: 1.75;
				@include max-screen($breakpoint-pc-small - 1px) {
					width: 95%;
				}
				@include max-screen($breakpoint-tablet - 1px) {
					width: 100%;
					display: block;
				}
				tbody {
					@include max-screen($breakpoint-tablet - 1px) {
						display: block;
					}
					tr {
						@include max-screen($breakpoint-tablet - 1px) {
							display: block;
						}
						font-size: 16px;
						+ tr > * {
							padding-top: 1em;
							@include max-screen($breakpoint-tablet - 1px) {
								padding-top: 0;
							}
						}
						th:not(:first-child) {
							margin-top: 1em;
						}
						th {
							padding-right: 50px;
							white-space: nowrap;
							vertical-align: top;
							span {
							}
							@include max-screen($breakpoint-tablet - 1px) {
								padding-right: 0;
								display: block;
								text-align: center;
								font-weight: bold;
								border-top: solid 1px #e0e0e0;
								border-bottom: solid 1px #e0e0e0;
							}
						}
						td {
							@include max-screen($breakpoint-tablet - 1px) {
								display: block;
								padding: 0.5em 1em;
							}
							ul {
								&.kinds {
									@extend %list_panc;
								}
								&.officer {
									@extend %list_multi;
								}
								&.office {
									font-size: 16px;
									font-weight: 400;
									li {
										+ li {
											margin-top: 1em;
										}
										h3 {
											font-size: 16px;
											font-weight: 400;
										}
										.access {
											.zip {
												margin-right: 1em;
												display: inline-block;
											}
											.building {
												margin-right: 0.5em;
												display: inline-block;
											}
										}
										.tel {
										}
									}
								}
								&.business {
									@extend %list_multi;
								}
								li {
									list-style: none;
								}
							}
						}
					}
				}
			}
		}
		.location {
			h2 {
				background: $color-orange;
				padding: 90px 0;
				color: #fff;
				font-size: 24px;
				@extend %fontEnBoldI;
				text-align: center;
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 7.03125vw;
				}
			}
			> ul {
				> li {
					display: flex;
					flex-wrap: wrap;
					list-style: none;
					@include max-screen($breakpoint-tablet - 1px) {
						display: block;
					}
					.infomation {
						flex: none;
						width: 50%;
						padding: 90px 0 85px;
						box-sizing: border-box;
						line-height: 2;
						font-size: 1.142vw;
						@include min-screen($breakpoint-pc-big) {
							font-size: 16px;
						}
						@include max-screen($breakpoint-pc-small - 1px) {
							padding: 3vw !important;
							font-size: 2vw;
						}
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							font-size: 3vw;
						}
						@include max-screen($breakpoint-mobile-portrait) {
							font-size: 4.375vw;
						}
						h3 {
							width: 75%;
							max-width: 500px;
							margin-bottom: 26px;
							@extend %fontEnMediumI;
							font-size: 2.857vw;
							line-height: 1;
							@include min-screen($breakpoint-pc-big) {
								font-size: 40px;
							}
							@include max-screen($breakpoint-pc-small - 1px) {
								width: auto;
								margin-bottom: 1.5vw;
								font-size: 3vw;
							}
							@include max-screen($breakpoint-tablet - 1px) {
								font-size: 4.583vw;
							}
							@include max-screen($breakpoint-mobile-portrait) {
								font-size: 7.5vw;
							}
							.lead {
								margin-top: 2.857vw;
								display: block;
								font-weight: bold;
								line-height: 1;
								font-size: 1.142vw;
								@include min-screen($breakpoint-pc-big) {
									margin-top: 40px;
									font-size: 16px;
								}
								@include max-screen($breakpoint-pc-small - 1px) {
									margin-top: 1.5vw;
									font-size: 2vw;
								}
								@include max-screen($breakpoint-tablet - 1px) {
									font-size: 3.33vw;
								}
								@include max-screen($breakpoint-mobile-portrait) {
									font-size: 5.625vw;
									font-weight: 400;
								}
							}
						}
						.access {
							width: 75%;
							max-width: 500px;
							margin-bottom: 10px;
							@include max-screen($breakpoint-pc-small - 1px) {
								width: auto;
							}
							.zip {
								display: block;
							}
							a {
								display: inline-block;
								line-height: 1.2;
								.building {
									padding-left: 1em;
								}
								@include max-screen($breakpoint-mobile-portrait) {
									display: block;
								}
							}
						}
						dl {
							width: 75%;
							max-width: 500px;
							margin-bottom: 10px;
							@include max-screen($breakpoint-pc-small - 1px) {
								width: auto;
								margin-bottom: 1vw;
							}
							dt {
								float: left;
								clear: left;
								&:after {
									content: "：";
								}
							}
							dd {
							}
						}
						.station {
							width: 75%;
							max-width: 500px;
							@include max-screen($breakpoint-pc-small - 1px) {
								width: auto;
							}
							h4 {
							}
							ul {
								li {
									list-style: none;
								}
							}
						}
					}
					figure {
						flex: none;
						width: 50%;
						display: block;
						position: relative;
						overflow: hidden;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
						}
						img {
							width: auto;
							min-width: 100%;
							height: auto;
							min-height: 100%;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							@include max-screen($breakpoint-tablet - 1px) {
								position: static;
								transform: translate(0);
							}
						}
					}
					.mapArea {
						background: #aaa;
						width: 100%;
						height: 400px;
						order: 3;
						flex: none;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							margin: 15px;
						}
					}
					&:nth-child(odd) {
						.infomation {
							padding-left: 7.142vw;
							order: 2;
							@include min-screen($breakpoint-pc-big) {
								padding-left: 100px;
							}
							> * {
								margin: {
									left: 0;
									right: auto;
								}
							}
						}
						figure {
							order: 1;
						}
					}
					&:nth-child(even) {
						.infomation {
							padding-right: 7.142vw;
							order: 1;
							@include min-screen($breakpoint-pc-big) {
								padding-right: 100px;
							}
							> * {
								margin: {
									left: auto;
									right: 0;
								}
							}
						}
						figure {
							order: 2;
						}
					}
				}
			}
		}
	}
	.privacypolicy & {
		article {
			@extend %maxwidth;
			line-height: 1.5;
			> h1 {
				@extend %ttlMain;
			}
			ul {
				li {
					margin-left: 1em;
					list-style: none;
					text-indent: -1em;
				}
			}
			section {
				h2 {
					margin-bottom: 10px;
				}
				dl {
					padding: 1em;
					@include max-screen($breakpoint-mobile-portrait) {
						padding: 0;
					}
					dt {
						margin-top: 1em;
						margin-bottom: 10px;
					}
					dd {
						margin: {
							left: 1em;
						}
					}
				}
				h3 {
					margin: 2em auto 0.5em;
				}
				.notice {
					text-align: right;
					span {
						display: inline-block;
						white-space: nowrap;
					}
				}
				table {
					width: 80%;
					margin: 10px auto;
					table-layout: fixed;
					border: solid 1px #efefef;
					@include max-screen($breakpoint-tablet - 1px) {
						display: block;
						width: auto;
						border-style: none;
					}
					tbody {
						@include max-screen($breakpoint-tablet - 1px) {
							display: block;
						}
					}
					tr {
						@include max-screen($breakpoint-tablet - 1px) {
							display: block;
						}
						> * {
							border: solid 1px #e0e0e0;
							@include max-screen($breakpoint-tablet - 1px) {
								display: block;
							}
						}
						th {
							text-align: center;
							vertical-align: middle;
							@include max-screen($breakpoint-tablet - 1px) {
								font-weight: bold;
							}
						}
						td {
							padding: 1em;
							@include max-screen($breakpoint-tablet - 1px) {
								border-style: solid none none;
							}
						}
					}
					th:first-child {
						width: 8em;
						padding: 10px;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							border-style: none none solid;
						}
					}
				}
				.p_mark {
					text-align: right;
					margin-top: 20px;
				}
			}
			.handling {
				margin-top: 50px;
				padding-top: 50px;
				border-top: solid 1px #e0e0e0;
				dl {
					dt {
					}
					dd {
						margin: {
							left: 2em;
						}
						table {
							@include max-screen($breakpoint-tablet - 1px) {
								margin-left: -2em;
							}
						}
					}
				}
			}
		}
	}
}

/* 220616 */
.bd-hero {
	margin-top: -150px;
	margin-bottom: 100px;
	@include max-screen($breakpoint-mobile-portrait) {
		margin-top: -80px;
	}

	&--page {
		position: relative;
		margin-top: calc(-150px + 87px);
		margin-bottom: 0;
		@extend .bd-hero-slide-item;
	}

	&__bg {
		@extend .bd-hero-slide-item__bg;
	}

	&__main {
		@extend .bd-hero-slide-item__main;
	}

	&__main-copy {
		@extend .bd-hero-slide-item__main-copy;
	}

	&__main-logo {
		@extend .bd-hero-slide-item__main-logo;
	}

	&.golfnote {
		background: #70b92c;
	}

	&.reachreach {
		background: #dcdddd;
		.bd-hero__main-logo {
			height: 300px;
			@include max-screen($breakpoint-mobile-portrait) {
				height: 42vw;
			}
		}
	}
}

.bd-hero-slide {
	.slick-dots {
		position: absolute;
		display: flex;
		justify-content: center;
		bottom: 10px;
		left: 0;
		right: 0;
		margin: auto;

		li {
			list-style: none;
			button {
				width: 30px;
				height: 30px;
				font-size: 0;
				background: none;
				border: none;
				position: relative;

				&:before {
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background: #fff;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			&.slick-active button:before {
				background: $color-orange;
			}
		}
	}
}

.bd-hero-slide-item {
	position: relative;
	height: 520px;
	@include max-screen($breakpoint-mobile-portrait) {
		height: 420px;
	}

	&__bg {
		width: 100%;
		height: 100%;
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}
	&__main {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	&__main-copy {
		font-family: $font_yumin;
		color: #fff;
		font-size: 30px;
		line-height: 1.7;
		font-weight: bold;
		@include max-screen($breakpoint-mobile-portrait) {
			font-size: 5vw;
		}
	}
	&__main-logo {
		height: 100px;
		margin: 30px auto 0;
		@include max-screen($breakpoint-mobile-portrait) {
			height: 14vw;
			margin-top: 20px;
		}

		img {
			width: auto;
			height: 100%;
		}
	}

	&--3 {
		background: #70b92c;
	}

	&--4 {
		background: #dcdddd;
		.bd-hero-slide-item__main-logo {
			height: 300px;
			@include max-screen($breakpoint-mobile-portrait) {
				height: 42vw;
			}
		}
	}
}

.stareaHead-marker {
	color: $color-orange;
	font-size: 120%;
	position: relative;
	background-image: linear-gradient(90deg, $color-orange, $color-orange);
	background-repeat: no-repeat;
	background-position: bottom left;
	background-size: 0% 10%;
	transition: cubic-bezier(0.2, 0.3, 0, 1) 1s background-size;
	line-height: 1.2;
	padding-bottom: 8px;
	@include max-screen($breakpoint-mobile-portrait) {
		padding-bottom: 3px;
		background-size: 0% 9%;
	}

	&.anim-start {
		background-size: 100% 10%;
		@include max-screen($breakpoint-mobile-portrait) {
			background-size: 100% 9%;
		}
	}
}

.bd-image {
	max-width: 570px;
	width: 90%;
	margin: 50px auto 30px;
	img {
		width: 100%;
		height: auto;
	}
}

.bd-biggertxt {
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	line-height: 1.4;
	margin-bottom: 100px;
	@include max-screen($breakpoint-mobile-portrait) {
		font-size: 20px;
	}
}

.service #contents .creation--top-up {
	position: relative;
	z-index: 1;
	margin: 180px 0;
	padding-top: 0;
	padding-bottom: 40px;
	border: 0;
	@include max-screen($breakpoint-mobile-portrait) {
		margin: 80px 0;
		padding: 20px 0;
	}

	&:before {
		content: "";
		display: block;
		max-width: 1240px;
		width: 90%;
		margin: 0 auto;
		position: absolute;
		z-index: -1;
		background: $color-orange;
		top: -80px;
		bottom: -80px;
		left: 0;
		right: 0;
		@include max-screen($breakpoint-mobile-portrait) {
			top: -20px;
			bottom: -20px;
		}
	}
}

.bd-cats {
	max-width: 1100px;
	width: 90%;
	margin: 30px auto 0;
	display: flex;
	flex-wrap: wrap;
	@include max-screen($breakpoint-mobile-portrait) {
		width: 80%;
	}
}

.bd-cat-item {
	display: flex;
	align-items: center;
	width: calc(50% - 20px);
	margin: 15px 0;
	margin-right: 40px;
	@include max-screen($breakpoint-mobile-portrait) {
		width: 100%;
		margin: 8px 0;
		margin-right: 0;
	}

	&:nth-child(2n) {
		margin-right: 0;
	}

	&__icon {
		width: 120px;
		height: 120px;
		background: #fff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		@include max-screen(768px) {
			width: 90px;
			height: 90px;
		}
		@include max-screen($breakpoint-mobile-portrait) {
			width: 66px;
			height: 66px;
		}

		img {
			width: 74px;
			height: auto;
			@include max-screen(768px) {
				width: 56px;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				width: 38px;
			}
		}
	}

	&__body {
		flex: 1;
		box-sizing: border-box;
		padding-left: 20px;
		@include max-screen(768px) {
			padding-left: 15px;
		}

		p {
			color: #fff;
			font-size: 20px;
			line-height: 1.4;
			@include max-screen(768px) {
				font-size: 17px;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 14px;
			}

			span {
				font-weight: bold;
				font-size: 150%;
				display: block;
				margin-bottom: 6px;
				@include max-screen($breakpoint-mobile-portrait) {
					font-size: 130%;
					margin-bottom: 4px;
				}
			}
		}
	}
}

.bd-project-area {
	background: #f0f0f0;
	margin-top: -180px;
	padding: 160px 0 100px;
	@include max-screen($breakpoint-mobile-portrait) {
		margin-top: -80px;
		padding: 80px 0 40px;
	}

	h2 {
		color: $color-orange;
		font-size: 50px;
		font-style: italic;
		font-family: Montserrat, sans-serif;
		text-align: center;
		line-height: 140%;
		margin-bottom: 50px;
		@include max-screen($breakpoint-mobile-portrait) {
			font-size: 34px;
		}
	}
}

.bd-projects {
	max-width: 1100px;
	width: 90%;
	margin: 40px auto;
}

.bd-project-card {
	display: block;
	position: relative;
	margin: 60px 0 30px;
	transition: 0.4s cubic-bezier(0.2, 0.3, 0, 1) transform;
	color: initial !important;
	border: none !important;
	@include max-screen($breakpoint-mobile-portrait) {
		margin: 50px 0 20px;
	}

	&__inner {
		border-radius: 20px;
		overflow: hidden;
		display: flex;
		min-height: 300px;
		background: #fff;
		transition: 0.8s cubic-bezier(0.3, 0.5, 0, 1) box-shadow;
		@include max-screen($breakpoint-mobile-portrait) {
			flex-direction: column;
			border-radius: 12px;
		}
	}

	&__icon {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 30px;
		transform: translateY(-50%);
		width: 60px;
		height: 60px;
		background: $color-orange;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		@include max-screen($breakpoint-mobile-portrait) {
			width: 50px;
			height: 50px;
		}
		img {
			width: 38px;
			height: auto;
			@include max-screen($breakpoint-mobile-portrait) {
				width: 28px;
			}
		}
	}
	&__img {
		width: 450px;
		position: relative;
		@include max-screen($breakpoint-mobile-portrait) {
			width: 100%;
			height: calc(90vw * 0.667);
		}
		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__body {
		width: 650px;
		box-sizing: border-box;
		padding: 30px 25px 65px;
		position: relative;
		@include max-screen($breakpoint-mobile-portrait) {
			width: 100%;
			padding: 25px 20px 65px;
		}

		p {
			line-height: 1.7;
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 14px;
			}
		}

		span.more {
			display: block;
			position: absolute;
			left: 25px;
			bottom: 30px;
			line-height: 1.2;
			color: $color-orange;
			text-decoration: underline;
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 14px;
			}
		}
	}

	&__head {
		margin-bottom: 12px;
		span {
			display: block;
			line-height: 1.2;
			font-size: 15px;
			margin-bottom: 12px;
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 13px;
			}
		}
	}
	&__head-ttl {
		font-size: 20px;
		font-weight: bold;
		line-height: 1.4;
		@include max-screen($breakpoint-mobile-portrait) {
			font-size: 18px;
		}
	}

	&:hover {
		transform: translateY(-3px);
		.bd-project-card__inner {
			box-shadow: 10px 10px 30px -5px rgba(#000, 0.2);
		}
	}
}

//buiness design detail page
.bd-detail {
	max-width: 1100px;
	width: 90%;
	margin: 0 auto;
	position: relative;
	z-index: 1;

	&__head {
		padding-top: 90px;
		margin-bottom: 45px;
		@include max-screen($breakpoint-mobile-portrait) {
			padding-top: 70px;
			margin-bottom: 30px;
		}
	}

	&__head-icon {
		width: 120px;
		height: 120px;
		background: $color-orange;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		transform: translateY(-50%);
		@include max-screen($breakpoint-mobile-portrait) {
			width: 80px;
			height: 80px;
		}

		img {
			width: 74px;
			height: auto;
			@include max-screen($breakpoint-mobile-portrait) {
				width: 48px;
			}
		}
	}
	&__head-tag {
		font-size: 20px;
		line-height: 1.2;
		font-weight: bold;
		display: block;
		@include max-screen($breakpoint-mobile-portrait) {
			font-size: 16px;
		}
	}

	&__heading {
		font-size: 30px;
		font-weight: bold;
		line-height: 1.6;
		margin: 30px 0 20px;
		@include max-screen($breakpoint-mobile-portrait) {
			font-size: 20px;
		}
	}

	&__body {
		p {
			line-height: 2;
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 14px;
				line-height: 1.8;
			}
		}
	}

	&__img {
		margin: 40px -20px;
		display: flex;
		justify-content: center;
		@include max-screen($breakpoint-mobile-portrait) {
			margin: 30px -5px;
		}

		+ .bd-detail__img {
			margin-top: 80px;
			@include max-screen($breakpoint-mobile-portrait) {
				margin-top: -10px;
			}
		}
	}

	&__img-item {
		flex: 1;
		max-width: 800px;
		margin: 0 20px;
		@include max-screen($breakpoint-mobile-portrait) {
			margin: 0 5px;
		}

		img {
			width: 100%;
			height: auto;
		}

		iframe {
			width: 100%;
			aspect-ratio: 16/9;
		}
	}

	~ .bd-project-area {
		margin-top: 0;
		padding-top: 80px;
	}
}

.bd-btn-wrap {
	display: flex;
	justify-content: center;
	margin: 80px 0;
	@include max-screen($breakpoint-mobile-portrait) {
		margin: 60px 0 80px;
	}

	.bd-btn {
		background: $color-orange;
		color: #fff !important;
		font-weight: bold;
		text-align: center;
		display: flex;
		width: 300px;
		height: 76px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		border: none !important;
		letter-spacing: 1px;
		transition: ease-out 0.12s opacity;
		@include max-screen($breakpoint-mobile-portrait) {
			max-width: 100%;
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

/* Downloads add 20221018 */
.dl {
	&Intro {
		margin-top: -32px;
		margin-bottom: 32px;
		text-align: center;

		@include sp {
			margin-top: -12px;
		}

		&_text {
			font-size: 16px;
			font-weight: 500;
			line-height: 2;

			@include sp {
				font-size: 14px;
			}
		}
	}

	&Cont {
		width: 100%;
		padding: 80px 0;
		background-color: #f0f0f0;

		@include sp {
			padding: 40px 0;
		}

		&_wrap {
			margin: 0 auto;

			@include pc {
				max-width: 1140px;
				padding: 0 20px;
			}

			@include sp {
				width: 90%;
			}
		}
	}

	&Info {
		$this: &;
		width: 100%;
		list-style: none;

		@include pc {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}

		&--col {
			@extend #{$this};

			@include pc {
				align-items: center;

				.dlInfo {
					&_list {
						&Pic {
							@include pc {
								max-width: 340px;
								margin-right: 32px;
							}
						}
					}
				}
			}
		}

		&_list {
			width: 30%;

			@include pc {
				max-width: 340px;
				margin-left: 5%;
			}

			@include sp {
				width: 100%;
			}

			& + & {
				@include sp {
					margin-top: 60px;
				}
			}

			&:nth-child(3n + 1) {
				margin-left: 0;
			}

			&:nth-child(n + 4) {
				@include pc {
					margin-top: 72px;
				}
			}

			&Pic {
				width: 100%;
				margin-bottom: 12px;

				img {
					width: 100%;
				}
			}

			&Lead {
				font-size: 16px;
				font-weight: bold;
				line-height: 2;

				@include sp {
					font-size: 14px;
				}
			}

			&Text {
				font-size: 16px;
				font-weight: 500;
				line-height: 2;
			}

			&Btn {
				width: 100%;
				margin: 24px auto 0;

				@include pc {
					max-width: 280px;
				}

				&Item {
					@extend %btn;
					display: block;
					text-align: center;
					color: #fff;
					width: 100%;
					white-space: normal;
					line-height: 1.6;
					padding: 20px 0;
					transition: ease-out 0.3s background;
					&:hover {
						background: #000;
					}

					@include sp {
						padding: 16px 0;
					}
				}
			}
		}
	}

	&Form {
		width: 100%;
		margin: 72px auto 0;

		@include pc {
			max-width: 800px;
		}

		@include sp {
			margin: 0;
		}

		table {
			width: 100%;
			table-layout: fixed;
			@include max-screen($breakpoint-tablet - 1px) {
				display: block;
			}
			tbody {
				@include max-screen($breakpoint-tablet - 1px) {
					display: block;
				}
				tr {
					font-size: 16px;
					vertical-align: top;
					line-height: 1.5;
					@include max-screen($breakpoint-tablet - 1px) {
						display: block;
					}
					> * {
					}
					th {
						width: 150px;
						padding: 32px 0;
						border-bottom: solid 1px #efefef;
						font-weight: bold;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							padding: 20px 0 0;
							border-bottom: none;
							display: inline-block;
						}
						br {
							@include max-screen($breakpoint-tablet - 1px) {
								display: none;
							}
						}
					}
					td {
						width: calc(100% - (150px + 88px));
						border-bottom: solid 1px #efefef;
						@include max-screen($breakpoint-tablet - 1px) {
							width: auto;
							padding: 0;
							display: block;
						}
						&:not(.must) {
							padding: 0;
						}
						&.must {
							width: 88px;
							padding: 32px 0;
							@include max-screen($breakpoint-tablet - 1px) {
								width: auto;
								padding: 18px 0 0;
								border-bottom: none;
								display: inline-block;
							}
							span {
								background: $color-orange;
								padding: 2px 4px;
								color: #fff;
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		.btnArea {
			text-align: center;
			.btn {
				@extend %btn_form;
				&.back {
					background: #b3b3b3;
				}
			}
		}
		.input_area_div {
			@extend %maxwidth;
			@include max-screen($breakpoint-mobile-portrait) {
				padding-top: 20px;
			}
			&:not(.current) {
				display: none;
			}
			.formText {
				width: 100%;
				border: none;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.5;
				@include placeholderColor(#a0a0a0);
				@include max-screen($breakpoint-tablet - 1px) {
					margin-top: auto;
				}
			}
			select {
				font-size: 16px;
			}
			input.formText {
				padding: 32px 10px;
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 10px;
				}
			}
			textarea.formText {
				height: 100px;
				margin-top: 22px;
				padding: 10px;
				@include max-screen($breakpoint-tablet - 1px) {
					margin-top: 0;
				}
			}
			label {
				display: inline-block;
				&:not(.select) {
					margin: 32px 2em 32px auto;
					@include max-screen($breakpoint-mobile-portrait) {
						margin: 0 auto;
					}
				}
				&:first-child {
					@include max-screen($breakpoint-mobile-portrait) {
						margin-top: 10px;
					}
				}
				&.select {
					@extend %select;
					@include min-screen($breakpoint-mobile) {
						margin: 21px 0 0;
						width: 300px;
					}
					@include max-screen($breakpoint-mobile-portrait) {
						margin: 10px auto 0;
					}
				}
				&.checkbox {
					@extend %checkbox;
				}
				&.radio {
					@extend %radio;
				}
				a {
				}
			}
		}
		#send_form_area {
			@extend %maxwidth;
			th {
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 5px 0;
				}
			}
			td {
				padding: 32px 0;
				line-height: 1.5;
				@include max-screen($breakpoint-tablet - 1px) {
					padding: 5px 0;
				}
				&.must {
					line-height: 1;
				}
			}
		}
		#send_msg {
			@extend %maxwidth;
			text-align: center;
			line-height: 1.5;
			padding: 50px 0 0;
			@include max-screen($breakpoint-mobile-portrait) {
				text-align: left;
			}
			.btnArea {
				padding: 100px 0 0;
				text-align: center;
				a {
					@extend %btn_a;
				}
			}
		}
		.agree {
			padding: 100px 0 50px;
			text-align: center;
			@include max-screen($breakpoint-pc-small - 1px) {
				padding: 50px 0;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 14px;
			}
			label {
				@include max-screen($breakpoint-mobile-portrait) {
					text-align: left !important;
					padding: 10px 10px 10px 40px;
					line-height: 1.5 !important;
				}
				&:before {
					@include max-screen($breakpoint-mobile-portrait) {
						left: 10px !important;
					}
				}
				&:after {
					@include max-screen($breakpoint-mobile-portrait) {
						left: 10px !important;
					}
				}
			}
		}
	}

	// btnarea
	.btnContact {
		margin-top: 60px;
		a {
			font-size: 22px;
			color: #ffffff !important;
			text-align: center;
			display: block;
			width: 90%;
			max-width: 400px;
			margin: 0 auto;
			padding: 26px 0;
			letter-spacing: 1px;
			background: #f25d32;
			border: 0;
			border-radius: 50px;
			transition: ease-out 0.3s background;
			&:hover {
				background: #000;
			}
			@include max-screen($breakpoint-mobile-portrait) {
				font-size: 16px;
				padding: 19px 0;
			}
		}
	}
}
/* end Downloads add 20221018 */

.pc {
	@include max-screen($breakpoint-mobile-portrait) {
		display: none;
	}
}

.sp {
	@include min-screen($breakpoint-mobile-portrait + 1px) {
		display: none;
	}
}
